import Layout2 from '@/layout/Layout2'

export default [{
  path: '/clientes',
  component: Layout2,
  children: [
    {
      path: 'buscadorServicios/',
      component: () => import('@/components/clientes/buscadorServicios')
    },
    {
      path: 'buscar/',
      component: () => import('@/components/clientes/buscar')
    },
    {
      path: 'miPerfil/',
      component: () => import('@/components/clientes/miPerfil')
    },
    {
      path: 'registroCliente/',
      component: () => import('@/components/clientes/registroCliente')
    },
    {
      path: 'listaDirecciones/',
      component: () => import('@/components/clientes/listaDirecciones')
    },
    {
      path: 'direcciones/',
      component: () => import('@/components/clientes/direcciones')
    },
    {
      path: 'inicio/',
      component: () => import('@/components/clientes/inicio')
    },
    {
      path: 'sucursalProv/:idSucursal',
      component: () => import('@/components/clientes/sucursalProv')
    },
    {
      path: 'agendar/',
      component: () => import('@/components/clientes/agendar')
    },
    {
      path: 'agenda/',
      component: () => import('@/components/clientes/agenda')
    }
  ]
}]
