import moment from 'moment'
export default {
  methods: {
    fechaDDMMMYYYY (date) {
      return moment(String(date)).format('DD/MMM/YYYY')
    },
    fechaDDMMMYYYYHHmm (date) {
      return moment(String(date)).format('DD/MMM/YYYY HH:mm')
    },
    horaHHMM (date) {
      return moment(String(date)).format('HH:mm')
    },
    formatoMoneda (val) {
      var num = parseFloat(val)
      return num.toFixed(2)
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Animación suave
      })
    },
    validarCorreo (correo) {
      const regexCorreo = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      return regexCorreo.test(correo)
    },
    removerEspacios (valor) {
      return valor.replace(/\s+/g, '')
    },
    dosDigitosString (num) {
      /*
        Si envio '1', regresa '01'
      */
      return num.toString().padStart(2, '0')
    },
    comparaHoras (tiempo1, tiempo2) {
      // Convert times to numbers representing minutes past midnight
      const tiempoAMinutos = (time) => {
        const [hours, minutes] = time.split(':').map(Number)
        return hours * 60 + minutes
      }
      const tiempoMinutes1 = tiempoAMinutos(tiempo1)
      const tiempoMinutes2 = tiempoAMinutos(tiempo2)
      return tiempoMinutes1 > tiempoMinutes2
    },
    sumarHoras (tiempo, horas) {
      var timeArray = tiempo.split(':') // Divide la cadena en horas, minutos, segundos
      var date = new Date()
      date.setHours(timeArray[0], timeArray[1], timeArray[2]) // Configura la hora actual
      // Suma las horas
      date.setHours(date.getHours() + horas)
      // Convierte nuevamente a formato "HH:MM:SS"
      return date.toTimeString().split(' ')[0]
    },
    sumarMinutos (tiempo, minutos) {
      var timeArray = tiempo.split(':')
      var date = new Date()
      date.setHours(timeArray[0], timeArray[1], timeArray[2])
      // Suma los minutos
      date.setMinutes(date.getMinutes() + minutos)
      // Convierte nuevamente a formato "HH:MM:SS"
      return date.toTimeString().split(' ')[0]
    },
    redondearMinutos (hora, intervalo) {
      // Convertir la cadena de texto a un objeto Date
      var [horas, minutos, segundos] = hora.split(':').map(Number)
      var fecha = new Date()
      fecha.setHours(horas, minutos, segundos)
      // Redondear los minutos al múltiplo más cercano del intervalo
      let minutosRedondeados = Math.ceil(minutos / intervalo) * intervalo
      // Ajustar la hora si los minutos son 60
      if (minutosRedondeados === 60) {
        fecha.setHours(fecha.getHours() + 1)
        minutosRedondeados = 0 // Reiniciar minutos a 0
      }
      fecha.setMinutes(minutosRedondeados)
      fecha.setSeconds(0) // Opcional: Reiniciar segundos a 0
      // Devolver el tiempo redondeado en formato 'HH:MM:SS'
      return fecha.toTimeString().split(' ')[0]
    },
    formatoHoraCorta (hora) {
      let [horas, minutos] = hora.split(':')
      horas = parseInt(horas, 10)
      // Determinar si es AM o PM
      const periodo = horas >= 12 ? 'PM' : 'AM'
      // Convertir al formato de 12 horas
      horas = horas % 12 || 12 // Si horas es 0 (medianoche), lo convertimos a 12
      return `${horas}:${minutos} ${periodo}`
    },
    redondeoDecimales (value, decimals) {
      if (typeof value === 'number' && typeof decimals === 'number') {
        const factor = Math.pow(10, decimals)
        return Math.round(value * factor) / factor
      }
      return null // Devuelve null si los parámetros no son válidos
    }
  }
}
